import * as Sentry from '@sentry/react';
import React, { useRef, useState } from 'react';
import ReactPlayer, { ReactPlayerProps } from 'react-player';

import { InstagramEmbed, TikTokEmbed } from 'react-social-media-embed';
import { clsx, getVideoTypeByUrl } from '../../utils/utils';
import * as styles from './Video.module.scss';

import { pushVideoStartEvent, withDataLayer } from '@digital-spiders/tracking-data';
import getYoutubeID from 'get-youtube-id';
import { RiPlayFill } from 'react-icons/ri';
import Image, { ImageType } from './Image';
import ImageWithFallbacks from './ImageWithFallbacks';
import ModalOverlay from './ModalOverlay';

export type VideoProps = ReactPlayerProps & {
  url: string;
  thumbnail?: ImageType;
  className?: string;
  isAVerticalVideo?: boolean;
  iconOnTopLeftCorner?: boolean;
  openInModal?: boolean;
};

/**
 * Used to get the thumbnail for the video, only tries to handle cases where
 * react-player autofetching is not good enough.
 * Can return either a url, a ReactElement, or null if we don't want to handle it specially.
 */
function useVideoThumbnail(url: string): string | React.ReactElement | false | true {
  const [thumbnail, setThumbnail] = useState<string | React.ReactElement | false | true>(false);

  if (thumbnail) {
    return thumbnail;
  }

  if (
    url.match(
      /^(https?:\/\/)?(((www\.)?youtube\.com\/(watch\?v=|embed\/|shorts\/))|(youtu\.be\/))[\w-]+$/,
    )
  ) {
    // YOUTUBE
    const youtubeId = getYoutubeID(url);
    if (!youtubeId) {
      throw new Error('Could not extract youtube id from url: ' + url);
    }
    const largeImgUrl = 'https://i.ytimg.com/vi/' + youtubeId + '/maxresdefault.jpg';
    const mediumImgUrl = 'https://i.ytimg.com/vi/' + youtubeId + '/sddefault.jpg';
    const smallImgUrl = 'https://i.ytimg.com/vi/' + youtubeId + '/hqdefault.jpg';

    setThumbnail(
      <ImageWithFallbacks
        className={clsx(styles.youtubeThumbnail, styles.thumbnail)}
        src={largeImgUrl}
        fallbackImage={[mediumImgUrl, smallImgUrl]}
        alt=""
        getConsiderSuccessAsError={e => {
          if (e.path && e.path[0] && (e.path[0].width !== 120 || e.path[0].height !== 90)) {
            return false;
          }
          if (e.target && (e.target.width !== 120 || e.target.height !== 90)) {
            return false;
          }
          return true;
        }}
      ></ImageWithFallbacks>,
    );
  } else if (url.includes('wistia')) {
    // WISTIA
    const wistiaUrlParts = url.split('/');
    const wistiaId = wistiaUrlParts[wistiaUrlParts.length - 1];
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    (async () => {
      try {
        const resp = await fetch(
          `https://fast.wistia.net/oembed?url=http://home.wistia.com/medias/${wistiaId}`,
        );
        if (resp.status === 200) {
          const data: { thumbnail_url: string } = await resp.json();
          setThumbnail(data.thumbnail_url);
        } else {
          throw new Error(
            `Got status code ${resp.status} when trying to fetch thumbnail for wistia video ${url}`,
          );
        }
      } catch (err) {
        Sentry.captureException(err);
        // fallback to autofetch, though it won't work
        setThumbnail(true);
      }
    })();
  } else {
    // true means autofetch, used because we don't have a
    // special fetching logic for the type of video used
    setThumbnail(true);
  }

  return thumbnail;
}

function Video(props: VideoProps): React.ReactElement {
  const type = getVideoTypeByUrl(props.url);

  const thumbnail = type === 'youtube' && !props.thumbnail && useVideoThumbnail(props.url);

  const containerRef = useRef<HTMLDivElement | null>(null);

  const [videoModalOpen, setVideoModalOpen] = useState(false);
  const [modalPositionY, setModalPositionY] = useState(0);

  const isAVerticalVideo = !!(
    props.isAVerticalVideo ||
    (type === 'youtube' && props.url.match(/\/shorts\//))
  );

  function renderVideo(
    url: string,
    type?: string,
    thumbnail?: ImageType,
    defaultThumbnail?: string | boolean | React.ReactElement,
  ) {
    if (type === 'tiktok') {
      return (
        <div className={clsx(styles.embed, styles.tiktok)}>
          <TikTokEmbed url={url} />
        </div>
      );
    }
    if (type === 'instagram') {
      return (
        <div className={clsx(styles.embed, styles.instagram)}>
          <InstagramEmbed url={url} />
        </div>
      );
    } else {
      return (
        <ReactPlayer
          light={
            thumbnail ? (
              <Image image={thumbnail} className={styles.thumbnail}></Image>
            ) : (
              defaultThumbnail
            )
          }
          playing
          controls
          width="100%"
          height={props.isAVerticalVideo ? '100%' : 'auto'}
          style={{
            aspectRatio: props.isAVerticalVideo ? '9/16' : '16/9',
            background: 'black',
            overflow: 'hidden',
          }}
          playIcon={
            <>
              <div className={styles.overlay}></div>
              <div
                className={clsx(
                  styles.iconWrapper,
                  props.iconOnTopLeftCorner && styles.iconOnTopLeftCorner,
                )}
              >
                <RiPlayFill className={styles.icon} />
              </div>
            </>
          }
          {...props}
          onStart={() => {
            withDataLayer(pushVideoStartEvent({ videoUrl: props.url }));
            if (props.onStart) {
              props.onStart();
            }
          }}
        />
      );
    }
  }

  return props.openInModal ? (
    <div
      ref={containerRef}
      className={clsx(styles.container, isAVerticalVideo && styles.verticalVideo, props.className)}
      onClick={e => {
        if (props.onVideoClick) {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-call
          props.onVideoClick(e);
        }
        const videoCenterY = containerRef.current
          ? containerRef.current.getBoundingClientRect().top +
            containerRef.current.getBoundingClientRect().height / 2
          : null;
        const windowHeight = window.document.documentElement?.getBoundingClientRect()?.height;
        setModalPositionY(videoCenterY ? videoCenterY : windowHeight / 2);
        setVideoModalOpen(true);
      }}
    >
      {!props.thumbnail ? (
        thumbnail
      ) : (
        <div className={styles.imageContainer}>
          <Image image={props.thumbnail} />
        </div>
      )}
      <div className={styles.overlay}></div>
      <div
        className={clsx(
          styles.iconWrapper,
          props.iconOnTopLeftCorner && styles.iconOnTopLeftCorner,
        )}
      >
        <RiPlayFill className={styles.icon} />
      </div>
      <ModalOverlay
        open={videoModalOpen}
        positionY={modalPositionY}
        onClose={() => {
          setVideoModalOpen(false);
        }}
      >
        {renderVideo(props.url, type, props.thumbnail, thumbnail)}
      </ModalOverlay>
    </div>
  ) : (
    <div
      className={clsx(styles.container, props.className, isAVerticalVideo && styles.verticalVideo)}
    >
      {renderVideo(props.url, type, props.thumbnail, thumbnail)}
    </div>
  );
}

export default Video;

import { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';

import {
  pushAnalyticsActivateEvent,
  pushOptimizeActivateEvent,
  withDataLayer,
} from '@digital-spiders/tracking-data';
import React from 'react';
import Button from '../components/ui/Button';
import ListPagination from '../components/ui/ListPagination';
import { SHOULD_RUN_GOOGLE_OPTIMIZE } from '../constants';
import * as styles from './hooks.module.scss';
import { sortBy } from './nodash';

export function useActivateGoogleOptimize(): boolean {
  const [isHidden, setIsHidden] = useState(SHOULD_RUN_GOOGLE_OPTIMIZE);
  // Initialize google optimize experiment on 'optimize.activate'
  useEffect(() => {
    if (SHOULD_RUN_GOOGLE_OPTIMIZE) {
      withDataLayer(pushOptimizeActivateEvent());
      // Send analytics.activate event with delay to ensure that Google Optimize
      // has had time to run the experiment before analytics triggers
      setTimeout(() => {
        withDataLayer(pushAnalyticsActivateEvent());
      }, 100);
      setIsHidden(false);
    }
  }, []);
  return isHidden;
}

export function useSingleRunCallback(callBack: () => void): void {
  const hasBeenCalled = useRef(false);
  if (hasBeenCalled.current) return;
  callBack();
  hasBeenCalled.current = true;
}

export interface ElementDimensions {
  width: number;
  height: number;
  top: number;
  left: number;
  x: number;
  y: number;
  right: number;
  bottom: number;
}

function getDimensionsObject(node: HTMLElement): ElementDimensions {
  const rect = node.getBoundingClientRect();

  return {
    width: rect.width,
    height: rect.height,
    // @ts-expect-error
    top: 'x' in rect ? rect.x : rect.top,
    // @ts-expect-error
    left: 'y' in rect ? rect.y : rect.left,
    // @ts-expect-error
    x: 'x' in rect ? rect.x : rect.left,
    // @ts-expect-error
    y: 'y' in rect ? rect.y : rect.top,
    right: rect.right,
    bottom: rect.bottom,
  };
}
export interface UseDimensionsOptions {
  measureOnResize?: boolean;
  measureOnScroll?: boolean;
}

export function useDimensions(
  ref: React.MutableRefObject<HTMLElement | null>,
  { measureOnResize = true, measureOnScroll = true }: UseDimensionsOptions = {},
): ElementDimensions | null {
  const [dimensions, setDimensions] = useState<ElementDimensions | null>(null);
  useLayoutEffect(() => {
    const node = ref.current;
    if (node) {
      const measure = () =>
        window.requestAnimationFrame(() => {
          setDimensions(getDimensionsObject(node));
        });
      measure();

      if (measureOnResize) {
        window.addEventListener('resize', measure);
      }
      if (measureOnScroll) {
        window.addEventListener('scroll', measure);
      }

      return () => {
        if (measureOnResize) {
          window.removeEventListener('resize', measure);
        }
        if (measureOnScroll) {
          window.removeEventListener('scroll', measure);
        }
      };
    }
    return;
  }, [ref.current]);

  return dimensions;
}

export interface WindowDimensions {
  width: number;
  height: number;
}

/**
 * Returns an object with the width and height of the window if it's being used in the browser,
 * otherwise returns null
 */
export function useWindowDimensions(): WindowDimensions | null {
  const [dimensions, setDimensions] = useState<WindowDimensions | null>(null);
  useLayoutEffect(() => {
    function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth,
      });
    }

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return dimensions;
}

export function useScrollY(): number | null {
  const [scrollY, setScrollY] = useState<number | null>(null);
  useLayoutEffect(() => {
    const getScrollY = () =>
      window.requestAnimationFrame(() => {
        setScrollY(window.scrollY);
      });
    getScrollY();
    window.addEventListener('scroll', getScrollY);
    return () => {
      window.removeEventListener('scroll', getScrollY);
    };
  }, []);

  return scrollY;
}

export function useOnLoadEffect(effectFunction: () => void): boolean {
  const [hasRunOnLoadEffect, setHasRunOnLoadEffect] = useState(false);
  useEffect(() => {
    effectFunction();
    setHasRunOnLoadEffect(true);
  }, []);
  return hasRunOnLoadEffect;
}

export function usePagination<Item>(
  items: Array<Item>,
  itemsPerPage: number,
): {
  currentPage: number;
  currentPageItems: Item[];
  getPageUrlPart: () => readonly [string, string | null];
  resetPagination: () => void;
  renderPagination: () => JSX.Element;
} {
  const [currentPage, setCurrentPage] = useState(1);
  const [nCardsVisible, setNCardsVisible] = useState(itemsPerPage);

  const currentPageItems = items.slice(
    (currentPage - 1) * nCardsVisible,
    currentPage * nCardsVisible,
  );

  const hasRunOnLoadEffect = useOnLoadEffect(() => {
    let initialPage = 1;
    const currentUrl = new URLSearchParams(window.location.search);
    const urlPageStr = currentUrl.get('page');
    if (urlPageStr && urlPageStr.match(/^\d+$/)) {
      const urlPage = parseInt(urlPageStr);
      const totalPages = Math.ceil(items.length / itemsPerPage);
      if (1 <= urlPage && urlPage <= totalPages) {
        initialPage = urlPage;
      }
    }
    setCurrentPage(initialPage || 1);
  });

  function getPageUrlPart(): readonly [string, string | null] {
    const totalPages = Math.ceil(items.length / itemsPerPage);
    const page = totalPages > 1 ? currentPage + '' : null;
    return ['page', page];
  }

  const resetPagination = () => {
    setCurrentPage(1);
    setNCardsVisible(itemsPerPage);
  };

  const renderPagination = () => (
    <>
      {hasRunOnLoadEffect && nCardsVisible < items.length && (
        <Button
          outlined
          onClick={() => {
            setNCardsVisible(nCardsVisible + itemsPerPage);
          }}
          className={styles.listPaginationMobileButton}
        >
          Load more
        </Button>
      )}
      {hasRunOnLoadEffect && items.length > itemsPerPage && (
        <ListPagination
          currentPage={currentPage}
          totalItems={items.length}
          itemsPerPage={nCardsVisible}
          onPrevPageClick={() => {
            setCurrentPage(currentPage - 1);
          }}
          onNextPageClick={() => {
            setCurrentPage(currentPage + 1);
          }}
          onPageClick={(pageNumber: number) => setCurrentPage(pageNumber)}
        ></ListPagination>
      )}
    </>
  );

  return {
    currentPage,
    currentPageItems,
    getPageUrlPart,
    resetPagination,
    renderPagination,
  };
}

export function useUpdateUrlFromFilters(
  getUrlParts: () => Array<readonly [string, string | null]>,
  dependencies: Array<unknown>,
): void {
  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    for (const [key, value] of getUrlParts()) {
      if (value) {
        urlSearchParams.set(key, value);
      }
    }

    const newQuery = urlSearchParams.toString();
    const newUrlPathAndQuery = window.location.pathname + (newQuery ? '?' + newQuery : '');
    history.replaceState(null, '', newUrlPathAndQuery);
  }, dependencies);
}

export function useAnimatedTypewritingTextsInfo(
  sentencesArrays: Array<Array<string>>,
  {
    transitionDuration = 750,
    transitionPeriod = 2000,
  }: { transitionDuration?: number; transitionPeriod?: number } = {},
): { currentSentenceIndex: number; nVisibleCharsArray: Array<number> } {
  if (
    !sentencesArrays
      .map(sentences => sentences.length)
      .every(sentencesLength => sentencesLength === sentencesArrays[0].length)
  ) {
    throw new Error('Not every sentencesArray has the same length');
  }
  const [currentSentenceIndex, setCurrentSentenceIndex] = useState(0);
  const [nVisibleCharsArray, setNVisibleCharsArray] = useState(
    sentencesArrays.map(sentences => sentences[0].length),
  );
  const [currentState, setCurrentState] = useState<'shrinking' | 'growing' | 'waiting'>('waiting');

  const sentenceTransitionsList = useRef<
    Array<{
      sentencesIndex: number;
      transitionTime: number;
      transitionDuration: number;
    }>
  >([]);

  useEffect(() => {
    if (currentState === 'waiting') {
      const timeout = setTimeout(() => {
        setCurrentState('shrinking');
      }, transitionPeriod);
      return () => {
        clearTimeout(timeout);
      };
    }

    if (sentenceTransitionsList.current.length === 0) {
      const currentSentences = sentencesArrays.map(sentences => sentences[currentSentenceIndex]);
      for (let i = 0; i < currentSentences.length; i++) {
        const sentence = currentSentences[i];

        const charTransitionDuration = transitionDuration / 2 / sentence.length;

        for (let j = 0; j < sentence.length; j++) {
          sentenceTransitionsList.current.push({
            sentencesIndex: i,
            transitionTime: j * charTransitionDuration,
            transitionDuration: charTransitionDuration,
          });
        }
      }
      sentenceTransitionsList.current = sortBy(
        sentenceTransitionsList.current,
        ({ transitionTime }) => transitionTime,
        'desc',
      );

      for (let i = sentenceTransitionsList.current.length - 1; i >= 0; i--) {
        const transition = sentenceTransitionsList.current[i];
        transition.transitionDuration =
          i === sentenceTransitionsList.current.length - 1
            ? 0
            : transition.transitionTime - sentenceTransitionsList.current[i + 1].transitionTime;
      }
    }

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const sentenceTransition = sentenceTransitionsList.current.pop()!;

    const timeout = setTimeout(() => {
      function setCurrentNVisibleChars(nVisibleChars: number) {
        const newNVisibleCharsArray = [...nVisibleCharsArray];
        newNVisibleCharsArray[sentenceTransition.sentencesIndex] = nVisibleChars;
        setNVisibleCharsArray(newNVisibleCharsArray);
      }

      if (currentState === 'shrinking') {
        setCurrentNVisibleChars(nVisibleCharsArray[sentenceTransition.sentencesIndex] - 1);
        if (sentenceTransitionsList.current.length === 0) {
          const newSentenceIndex = (currentSentenceIndex + 1) % sentencesArrays[0].length;
          setCurrentSentenceIndex(newSentenceIndex);
          setCurrentState('growing');
        }
      }
      if (currentState === 'growing') {
        setCurrentNVisibleChars(nVisibleCharsArray[sentenceTransition.sentencesIndex] + 1);
        if (sentenceTransitionsList.current.length === 0) {
          setCurrentState('waiting');
        }
      }
    }, sentenceTransition.transitionDuration);
    return () => {
      clearTimeout(timeout);
    };
  }, [...nVisibleCharsArray, currentState]);
  return {
    currentSentenceIndex,
    nVisibleCharsArray,
  };
}

/**
 * Allows using intersection observer.
 * Can be used to detect full or partial visibility, or any other intersection logic needed.
 * @param options
 * @param options.threshold Either a single number or an array of numbers between 0 and 1 (the default), indicating at what percentage of the target’s visibility the observer’s callback should be executed. Defaults to 1.
 * @param options.root The Element that is used as the viewport for checking visibility of the target. Defaults to the browser viewport if not specified or if null.
 * @param options.rootMargin Margin around the root. Can have values similar to the CSS margin property. The values can be percentages. This set of values serves to grow or shrink each side of the root element’s bounding box before computing intersections. Defaults to all zeros.
 */
export function useIntersectionObserver(
  options: { threshold?: number; root?: Element | Document | null; rootMargin?: string } = {},
): [ref: (node: HTMLElement | null) => void, entry: IntersectionObserverEntry | null] {
  const { threshold = 1, root = null, rootMargin = '0px' } = options;
  const [entry, setEntry] = useState<IntersectionObserverEntry | null>(null);

  const previousObserver = useRef<IntersectionObserver | null>(null);

  const customRef = useCallback(
    (node: HTMLElement | null) => {
      if (previousObserver.current) {
        previousObserver.current.disconnect();
        previousObserver.current = null;
      }

      if (node?.nodeType === Node.ELEMENT_NODE) {
        const observer = new IntersectionObserver(
          ([entry]) => {
            setEntry(entry);
          },
          { threshold, root, rootMargin },
        );

        observer.observe(node);
        previousObserver.current = observer;
      }
    },
    [threshold, root, rootMargin],
  );

  return [customRef, entry];
}
